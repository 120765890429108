import { FormGroup, Label, Row, Col } from "reactstrap";
import { NumericFormat } from "react-number-format";
import Select from "react-select";
import dataJSON from "assets/data.json";

const Form = ({ data, onChange }) => {
  const optionShipFrom = [
    {
      value: "TP. HỒ CHÍ MINH",
      label: "TP. HỒ CHÍ MINH",
    },
    {
      value: "TP. HÀ NỘI",
      label: "TP. HÀ NỘI",
    },
  ];

  const optionShipTo = dataJSON.map((item) => ({
    value: item?.countryCode,
    label: item?.countryName,
  }));

  const { shipTo, shipFrom, weight } = data;

  return (
    <div className="container-app mt-5">
      <h5 className="text-uppercase fw-bolder">ước tính cước phí</h5>
      <FormGroup className="f-floating">
        <Row>
          <Col md="6" sm="12">
            <Label
              for="guiTu"
              className="float-label px-2 py-0 mb-0 text-uppercase"
            >
              Gửi từ *
            </Label>
            <div id="guiTu" className="pt-2">
              <Select
                options={optionShipFrom}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: "#00A4FF",
                  },
                })}
                value={shipFrom ? { value: shipFrom, label: shipFrom } : []}
                placeholder="Chọn nơi gửi đi"
                components={{
                  IndicatorSeparator: () => null,
                }}
                onChange={(item) => onChange("shipFrom", item?.value)}
                className="dropdown-container"
                classNamePrefix="dropdown-select"
              />
            </div>
          </Col>
          <Col md="6" sm="12" className="mt-3 mt-md-0">
            <Label
              for="guiDen"
              className="float-label px-2 py-0 mb-0 text-uppercase"
            >
              Gửi đến *
            </Label>
            <div id="guiDen" className="pt-2">
              <Select
                options={optionShipTo}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                })}
                value={shipTo ? { value: shipTo, label: shipTo } : []}
                placeholder="Chọn nơi gửi đến"
                components={{
                  IndicatorSeparator: () => null,
                }}
                onChange={(item) => onChange("shipTo", item?.value)}
                className="dropdown-container"
                classNamePrefix="dropdown-select"
              />
            </div>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup className="f-floating">
        <Row>
          <Col md="6" sm="12">
            <Label
              for="trongLuong"
              className="float-label px-2 py-0 mb-0 text-uppercase"
            >
              Trọng lượng (Kg) *
            </Label>
            <div id="trongLuong" className="pt-2">
              <NumericFormat
                className="form-control rounded-0 input-number"
                value={weight}
                onValueChange={(values, _) =>
                  onChange("weight", values?.floatValue)
                }
                allowNegative={false}
              />
            </div>
          </Col>
          <Col md="6" sm="12" className="mt-3 mt-md-0">
            <Label
              for="soTienThuHo"
              className="float-label px-2 py-0 mb-0 text-uppercase"
            >
              Số tiền thu hộ (VNĐ)
            </Label>
            <div id="soTienThuHo" className="pt-2">
              <NumericFormat
                className="form-control rounded-0 input-number"
                value="0"
                allowNegative={false}
                thousandSeparator=","
              />
            </div>
          </Col>
        </Row>
      </FormGroup>
    </div>
  );
};

export default Form;
