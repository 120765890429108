import { Card, CardImgOverlay, CardTitle, CardText } from "reactstrap";
import HeroBannerImg from "assets/img/hero-banner.png";

const HeroBanner = () => {
  return (
    <Card inverse>
      <img
        alt="hero banner"
        className="hero-banner"
        src={HeroBannerImg}
      />

      <CardImgOverlay className="text-center mt-2">
        <CardTitle tag="h5" className="mt-5">
          VẬN CHUYỂN QUỐC TẾ SKYLINK EXPRESS - NHANH CHÓNG - AN TOÀN - GIÁ RẺ
        </CardTitle>
        <CardText tag="h2" className="mt-3">
          Ước tính cước phí
        </CardText>
      </CardImgOverlay>
    </Card>
  );
};

export default HeroBanner;
