import { useState } from "react";
import { Card, CardTitle, CardBody, Row, Col } from "reactstrap";
import { ReactComponent as Mail } from "assets/img/mail.svg";
import { ReactComponent as Breakfast } from "assets/img/breakfast.svg";
import { ReactComponent as Boxes } from "assets/img/boxes.svg";
import { ReactComponent as Delivery } from "assets/img/delivery.svg";
import { ReactComponent as Truck } from "assets/img/truck.svg";
import { ReactComponent as MailActive } from "assets/img/mail-active.svg";
import { ReactComponent as BreakfastActive } from "assets/img/breakfast-active.svg";
import { ReactComponent as BoxesActive } from "assets/img/boxes-active.svg";
import { ReactComponent as DeliveryActive } from "assets/img/delivery-active.svg";
import { ReactComponent as TruckActive } from "assets/img/truck-active.svg";

const CardView = ({ onChange }) => {
  const [iconStatus, setIconStatus] = useState("");
  const cards = [
    {
      name: "0 - 5Kg",
      value: 2.5,
      icon: <Mail />,
      iconActive: <MailActive />,
    },
    {
      name: "5 - 10Kg",
      value: 7.5,
      icon: <Breakfast />,
      iconActive: <BreakfastActive />,
    },
    {
      name: "10 - 15Kg",
      icon: <Boxes />,
      iconActive: <BoxesActive />,
      value: 12.5,
    },
    {
      name: "15 - 20Kg",
      icon: <Delivery />,
      iconActive: <DeliveryActive />,
      value: 17.5,
    },
    {
      name: "> 20Kg",
      icon: <Truck />,
      iconActive: <TruckActive />,
      value: 20.5,
    },
  ];

  return (
    <div className="container-app mt-5 d-none d-lg-block">
      <h5 className="text-uppercase fw-bolder">Khối Lượng ước tính</h5>
      <Row className="d-flex justify-content-between mt-3">
        {cards.map((item, key) => (
          <Col key={key}>
            <Card
              className="text-center card-content"
              key={key}
              onClick={() => onChange(item.value)}
              onMouseOver={() => setIconStatus(item?.name)}
              onMouseOut={() => setIconStatus("")}
            >
              <div className="mt-3">
                {iconStatus === item?.name ? item.iconActive : item.icon}
              </div>
              <CardBody className="mb-0">
                <CardTitle tag="h5">{item.name}</CardTitle>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default CardView;
