import React, { useState } from "react";
import { ReactComponent as Logo } from "assets/img/logofull.svg";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledCollapse,
} from "reactstrap";
import {
  FaHome,
  FaServicestack,
  FaList,
  FaGripVertical,
  FaTasks,
  FaSearch,
  FaSlack,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";

const Header = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [changeIcon, setChangeIcon] = useState({
    showService: false,
    showSearch: false,
  });

  const toggleNavbar = () => setCollapsed(!collapsed);

  const navigationHeader = [
    {
      name: "Trang chủ",
      icon: <FaHome size={14} />,
      link: "https://vanchuyendailoan.com",
    },
    {
      id: "showService",
      name: "Dịch vụ vận chuyển",
      icon: <FaServicestack size={14} />,
      type: "dropdown",
      link: "https://vanchuyendailoan.com/dich-vu",
      listData: [
        {
          icon: <FaSlack size={14} />,
          name: "VẬN CHUYỂN ĐÀI LOAN",
          link: "https://vanchuyendailoan.com/dich-vu-van-chuyen-hang-di-dai-loan.html",
        },
        {
          name: "VẬN CHUYỂN ĐÀI LOAN",
          icon: <FaSlack size={14} />,
          link: "https://vanchuyendailoan.com/dich-vu-van-chuyen-hang-di-dai-loan.html",
        },
        {
          name: "VẬN CHUYỂN ĐÀI LOAN VỀ VN",
          icon: <FaSlack size={14} />,
          link: "https://vanchuyendailoan.com/van-chuyen-gui-hang-tu-dai-loan-ve-viet-nam.html",
        },
        {
          name: "VẬN CHUYỂN SINGAPORE",
          icon: <FaSlack size={14} />,
          link: "https://vanchuyendailoan.com/dich-vu-van-chuyen-gui-hang-di-singapore.html",
        },
        {
          name: "VẬN CHUYỂN MALAYSIA",
          icon: <FaSlack size={14} />,
          link: "https://vanchuyendailoan.com/dich-vu-van-chuyen-gui-hang-di-malaysia.html",
        },
        {
          name: "VẬN CHUYỂN ĐI ÚC",
          icon: <FaSlack size={14} />,
          link: "https://vanchuyendailoan.com/dich-vu-van-chuyen-hang-di-uc.html",
        },
        {
          name: "VẬN CHUYỂN ĐI MỸ",
          icon: <FaSlack size={14} />,
          link: "https://vanchuyendailoan.com/dich-vu-van-chuyen-hang-di-my.html",
        },
        {
          name: "VẬN CHUYỂN ĐI CANADA",
          icon: <FaSlack size={14} />,
          link: "https://vanchuyendailoan.com/dich-vu-gui-hang-di-canada-gia-re.html",
        },
        {
          name: "VẬN CHUYỂN NEW ZEALAND",
          icon: <FaSlack size={14} />,
          link: "https://vanchuyendailoan.com/dich-vu-gui-hang-di-new-zealand-gia-re.html",
        },
        {
          name: "GỬI HÀNG ĐI PHILIPPINES",
          icon: <FaSlack size={14} />,
          link: "https://vanchuyendailoan.com/gui-hang-di-philippines-chuyen-tuyen-gia-re.html",
        },
        {
          name: "GỬI HÀNG ĐI DUBAI",
          icon: <FaSlack size={14} />,
          link: "https://vanchuyendailoan.com/gui-hang-di-dubai-chuyen-tuyen-gia-re.html",
        },
        {
          name: "VẬN CHUYỂN NHẬT BẢN",
          icon: <FaSlack size={14} />,
          link: "https://vanchuyendailoan.com/dich-vu-van-chuyen-gui-hang-di-nhat-ban.html",
        },
        {
          name: "VẬN CHUYỂN HÀN QUỐC",
          icon: <FaSlack size={14} />,
          link: "https://vanchuyendailoan.com/dich-vu-van-chuyen-gui-hang-di-han-quoc.html",
        },
        {
          name: "VẬN CHUYỂN TRUNG QUỐC",
          icon: <FaSlack size={14} />,
          link: "https://vanchuyendailoan.com/dich-vu-van-chuyen-hang-di-trung-quoc.html",
        },
      ],
    },
    {
      name: "Gửi hàng Đài Loan",
      icon: <FaList size={14} />,
      link: "https://vanchuyendailoan.com/gui-hang-dai-loan",
    },
    {
      name: "Gửi hàng Malaysia",
      icon: <FaGripVertical size={14} />,
      link: "https://vanchuyendailoan.com/gui-hang-malaysia",
    },
    {
      name: "Gửi hàng Singapore",
      icon: <FaTasks size={14} />,
      link: "https://vanchuyendailoan.com/gui-hang-singapore",
    },
    {
      id: "showSearch",
      name: "Tra cứu vận đơn",
      icon: <FaSearch size={14} />,
      type: "dropdown",
      listData: [
        {
          name: "Tracking Đài Loan",
          link: "https://www.t-cat.com.tw/inquire/trace.aspx",
          icon: <FaSearch size={14} />,
        },
        {
          name: "Tracking Các Nước Khác",
          link: "http://mytracking.top/tracking/SKYLINKEXPRESS/",
          icon: <FaSearch size={14} />,
        },
      ],
    },
  ];

  return (
    <Navbar
      expand="lg"
      container={false}
      className="d-flex flex-column container-fluid py-0 px-0"
    >
      <div className="w-100 d-flex justify-content-center justify-content-between header-brand container-app">
        <NavbarBrand href="https://vanchuyendailoan.com" className="py-2">
          <Logo />
          <label className="header-brand--text d-none d-md-inline-flex">
            công ty TNHH vận tải quốc tế Skylink express
          </label>
        </NavbarBrand>
        <div className="d-inline-flex">
          <NavLink
            className="justify-content-end align-self-center header-brand--btn "
            href="https://vanchuyendailoan.com/lien-he"
          >
            Liên hệ
          </NavLink>
          <NavbarToggler onClick={toggleNavbar} className="mobile-menu ms-3" />
        </div>
      </div>
      <div className="w-100 container-app pt-lg-2 pb-lg-1">
        <Collapse isOpen={!collapsed} navbar>
          <Nav
            className="w-100 d-none d-lg-flex justify-content-between"
            navbar
          >
            {navigationHeader.map((item, key) =>
              item?.type !== "dropdown" ? (
                <NavItem key={key}>
                  <NavLink href={item?.link}>
                    {item?.icon}
                    {item?.name}
                  </NavLink>
                </NavItem>
              ) : (
                <li className="nav-item dropdown" key={key}>
                  <a className="dropbtn" href={item?.link}>
                    {item?.icon} {item?.name} <FaChevronDown size={12} />
                  </a>
                  <div className="dropdown-content">
                    {item?.listData.map((itm, idx) => (
                      <a key={idx} href={itm?.link}>
                        {itm?.icon} {itm?.name}
                      </a>
                    ))}
                  </div>
                </li>
              )
            )}
          </Nav>
          <Nav className="w-100 d-block d-lg-none my-3 my-lg-0">
            {navigationHeader.map((item, key) => {
              const dName = item?.name.replaceAll(" ", "");
              return item?.type !== "dropdown" ? (
                <NavItem key={`mobile${key}`}>
                  <NavLink href={item?.link}>
                    {item?.icon}
                    {item?.name}
                  </NavLink>
                </NavItem>
              ) : (
                <div key={`mobile${key}`}>
                  <NavItem className="w-100 nav-item d-flex justify-content-between">
                    <NavLink href={item?.link} className="w-95">
                      {item?.icon}
                      {item?.name}
                    </NavLink>
                    <label
                      className="dropbtn w-5"
                      id={dName}
                      onClick={() =>
                        setChangeIcon({
                          ...changeIcon,
                          [item?.id]: !changeIcon[item?.id],
                        })
                      }
                    >
                      {changeIcon[item?.id] ? (
                        <FaChevronUp size={12} />
                      ) : (
                        <FaChevronDown size={12} />
                      )}
                    </label>
                  </NavItem>
                  <UncontrolledCollapse toggler={dName}>
                    <ul className="accordion-body mb-0 p-0 navbar-nav ms-3 mb-3">
                      {item?.listData.map((detl, idx) => (
                        <NavItem
                          key={idx}
                          className="w-100 nav-item d-flex justify-content-between"
                        >
                          <NavLink href={detl?.link}>
                            {detl?.icon}
                            {detl?.name}
                          </NavLink>
                        </NavItem>
                      ))}
                    </ul>
                  </UncontrolledCollapse>
                </div>
              );
            })}
          </Nav>
        </Collapse>
      </div>
    </Navbar>
  );
};

export default Header;
