import LogoImg from "assets/img/logo.png";
import { ReactComponent as Zalo } from "assets/img/zalo.svg";
import { ReactComponent as Facebook } from "assets/img/facebook.svg";
import { ReactComponent as Email } from "assets/img/email.svg";

const HeroBanner = () => {
  return (
    <footer className="pt-4 my-md-5 pt-md-5 container-app">
      <div className="border-top">
        <section className="d-flex justify-content-center justify-content-between py-4">
          <div className="me-5">
            <img
              alt="logo"
              className="hero-banner logo-on-footer"
              src={LogoImg}
            />
          </div>

          <div className="d-none d-lg-flex justify-content-center m-auto ">
            <a
              href="https://vanchuyendailoan.com/gioi-thieu"
              className="me-4 link-secondary"
            >
              Giới thiệu Skylink Express
            </a>
            <a
              href="https://vanchuyendailoan.com/tu-van"
              className="me-4 link-secondary"
            >
              Câu hỏi thường gặp
            </a>
            <a
              href="https://vanchuyendailoan.com/luu-y-khi-gui-hang-di-dai-loan"
              className="me-4 link-secondary"
            >
              Lưu ý khi gửi hàng
            </a>
          </div>

          <div className="d-flex justify-content-center my-auto">
            <a
              href="https://chat.zalo.me/?phone=0901851479"
              className="me-4 link-secondary"
            >
              <Zalo />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100088784942513"
              className="me-4 link-secondary"
            >
              <Facebook />
            </a>
            <a
              href="mailto:richardnguyen1479@gmail.com"
              className="me-4 link-secondary"
            >
              <Email />
            </a>
          </div>
        </section>
        <div className="d-grid d-lg-none">
          <a
            href="https://vanchuyendailoan.com/gioi-thieu"
            className="me-4 link-secondary mt-3"
          >
            Giới thiệu Skylink Express
          </a>
          <a
            href="https://vanchuyendailoan.com/tu-van"
            className="me-4 link-secondary mt-3"
          >
            Câu hỏi thường gặp
          </a>
          <a
            href="https://vanchuyendailoan.com/luu-y-khi-gui-hang-di-dai-loan"
            className="me-4 link-secondary my-3"
          >
            Lưu ý khi gửi hàng
          </a>
        </div>
      </div>
    </footer>
  );
};

export default HeroBanner;
