import { useState } from "react";

import { Button } from "reactstrap";
import dataJSON from "assets/data.json";
import Header from "Header";
import HeroBanner from "HeroBanner";
import CardView from "CardView";
import Form from "Form";
import Footer from "Footer";
import Results from "Results";
import { find } from "lodash";

const App = () => {
  const [formField, setFormField] = useState({
    shipTo: "",
    shipFrom: "",
    weight: 0,
  });

  const [traCuu, setTraCuu] = useState([]);

  const { shipTo, weight } = formField;

  const onChangeRangeWeight = (value) => {
    setFormField({
      ...formField,
      weight: value,
    });
  };

  const onChangeField = (field, value) => {
    setFormField({
      ...formField,
      [field]: value,
    });
  };

  const onFindShipper = () => {
    const sCountry = find(dataJSON, { countryCode: shipTo });
    const shipperInfo = find(sCountry?.shipper?.freight, (o) => {
      return o.weight >= weight;
    });
    const fWeight = Math.ceil(weight / 0.5) * 0.5;
    const result = [
      {
        name: weight > 20 ? "Dịch vụ tiết kiệm" : sCountry?.shipper?.name,
        weight,
        fee:
          weight > 20 && shipperInfo?.fee
            ? (fWeight * shipperInfo?.fee).toFixed(0)
            : shipperInfo?.fee ?? "Liên hệ",
        times: sCountry?.shipper?.times,
      },
    ];
    setTraCuu(result);
  };

  const isShowTraCuu = !!shipTo && !!shipTo && weight > 0;

  return (
    <>
      <Header />
      <HeroBanner />
      {traCuu?.length > 0 && <Results data={traCuu} />}
      <CardView onChange={onChangeRangeWeight} />
      <Form data={formField} onChange={onChangeField} />
      <div className="container-app mt-5">
        <Button
          color={!isShowTraCuu ? "secondary" : ""}
          className="w-100 text-uppercase rounded-0 tra-cuu-btn"
          disabled={!isShowTraCuu}
          onClick={onFindShipper}
        >
          Tra cứu
        </Button>
      </div>
      <Footer />
    </>
  );
};

export default App;
