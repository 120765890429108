import { Table } from "reactstrap";

const Results = ({ data }) => {
  const formatNumberWithPoint = (amount) => {
    return amount?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  return data?.length > 0 ? (
    <div className="container-app mt-5">
      <Table bordered>
        <thead>
          <tr className="tbl-header">
            <th>Tên dịch vụ</th>
            <th>Khối lượng</th>
            <th>Giá cước</th>
            <th>thời gian</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, key) => (
            <tr key={key}>
              <th>{item?.name}</th>
              <td>{item?.weight}</td>
              <td>{formatNumberWithPoint(item?.fee)}</td>
              <td>{item?.times}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  ) : null;
};

export default Results;
